import { MdOutlinePhoneEnabled } from 'react-icons/md'
import { TbMapPinCheck } from 'react-icons/tb'
import { HiOutlineMail } from 'react-icons/hi'

export default function FooterAddress ()
{
	return (
		<address className="c-footer__address">
			<div className="c-footer__address-item">
				<div className="c-footer__addr-icon">
					<TbMapPinCheck className="c-footer__addr-svg" />
				</div>

				<p className="c-footer__addr-text">
					La Bergerie Nationale
					<br />
					Parc du Château&nbsp;- CS&nbsp;40609
					<br />
					78514 Rambouillet
				</p>
			</div>

			<div className="c-footer__address-item">
				<div className="c-footer__addr-icon">
					<MdOutlinePhoneEnabled className="c-footer__addr-svg" />
				</div>

				<p className="c-footer__addr-text">
					<a href="tel:+33761320707" className="c-footer__addr-a">
						07 61 32 07 07
					</a>
				</p>
			</div>

			<div className="c-footer__address-item">
				<div className="c-footer__addr-icon">
					<HiOutlineMail className="c-footer__addr-svg" />
				</div>

				<p className="c-footer__addr-text">
					<a href="mailto:info@example.com"
						className="c-footer__addr-a">
						info@example.com
					</a>
				</p>
			</div>
		</address>
	);
}