import { useCallback, useState } from 'react'

import Spinner from '../../Spinners/Spinner'

import request from '../../Functions/ajax/request'
import bemit   from '../../Functions/bemit'

import setPagesStore from '../../../StoresFunctions/setPagesStore'
import setRolesStore from '../../../StoresFunctions/setRolesStore'

export default function PanelWelcome (props)
{
	const [disconnecting, setDisconecting] = useState(false);

	const logout = useCallback(function (e)
	{
		const controller = new AbortController();

		setDisconecting(true);

		(async function logout ()
		{
			try
			{
				var { pages, version } = await request('/auth/logout')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				if (controller?.signal?.aborted) return;
				return;
			}

			setPagesStore({ pages, version });
			setRolesStore({ });

			setDisconecting(false);
		})();

		return () => controller?.abort?.();
	},
	[]);

	return (
		<div className="c-panel-welcome">
			<div className="c-panel-welcome__title">
				Bienvenue sur OAA Online
			</div>

			<div className="c-panel-welcome__text">
				Vous pouvez maintenant accéder aux formations.
			</div>

			<div className="c-panel-welcome__logout">
				<button type="button" className={ bemit('c-button') }
					onClick={ logout }>
					Déconnexion
					<Spinner active={ disconnecting }
						bemit={ ['bg-white', 'primary'] }
					/>
				</button>
			</div>
		</div>
	);
}