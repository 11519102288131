import { Link } from 'react-router-dom'

import FooterAddress from './FooterAddress'

export default function FooterContent ()
{
	return (

		<div className="o-container c-footer__main-content">

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Qui sommes-nous
				</h2>

				<p className="c-footer__description">
					Cras faucibus ornare ipsum, non luctus leo imperdiet sit amet. Praesent egestas orci eu risus iaculis luctus. Phasellus maximus orci metus. Nullam enim ex, facilisis at lacinia sed, luctus vitae dolor.
				</p>
			</div>

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Contactez-nous
				</h2>

				<FooterAddress />
			</div>

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Nos formations
				</h2>

				<ul className="c-footer__main-links">
					<li className="c-footer__main-item">
						<Link to="/formations-theoriques"
							className="c-footer__main-links-a">
							Formations théoriques
						</Link>
					</li>

					<li className="c-footer__main-item">
						<Link to="formations-pratiques"
							className="c-footer__main-links-a">
							Formations pratiques
						</Link>
					</li>
				</ul>
			</div>

		</div>
	);
}