import { useEffect, useRef, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import FormStatus from '../../Form/FormStatus'
import Spinner    from '../../Spinners/Spinner'
import Input      from '../../Form/Elements/Input'

import request from '../../Functions/ajax/request'
import bemit   from '../../Functions/bemit'

import RolesStore from '../../../Stores/Roles'
import PagesStore from '../../../Stores/Pages'

import setRolesStore from '../../../StoresFunctions/setRolesStore'
import setPagesStore from '../../../StoresFunctions/setPagesStore'

export default function PanelLoginForm ({ afterLogin, ...props })
{
	const navigate = useNavigate();

	const home = RolesStore.useState(s => s.home);
	const pages_version = PagesStore.useState(s => s.version);

// évite de déclencher des hooks ou ajouter des dépendances pour rien
	const _ref_version = useRef();

	const [status, setStatus] = useState({ });

	const [loading, setLoading] = useState(false);
	const [redirect, setRedirect] = useState();

	useEffect(function ()
	{
		if (!home || !redirect) return;

		if (pages_version !== _ref_version.current) return; // store pas encore màj

		setRedirect(false);

		if (home !== '/')
		{
			navigate(home);
		}
	},
	[pages_version, afterLogin, redirect, home, navigate]);

	return (
		<>
			<div className="c-panel__spacer" />

			<div className="c-panel__title">
				Connexion étudiant OAA
			</div>

			<form className="c-user-panel__form"
				onSubmit={ login }>

				<Input name="email" label="E-mail" type="email"
					autoComplete="current-email"
					error={ status.fields?.email?.error }
					bemit={ ['dark-bg'] }
				/>

				<Input className="u-margin-top-10"
					name="password" label="Mot de passe" type="password"
					autoComplete="current-password"
					error={ status.fields?.password?.error }
					bemit={ ['dark-bg'] }
				/>

				<div className="u-full-width u-text-right">

					<Link to="/mot-de-passe-oublie">
						Mot de passe oublié&nbsp;?
					</Link>

				</div>

				<FormStatus { ...status } />

				<div className="u-full-width u-flex u-content-center u-margin-top-15">

					<button type="submit"
						className={ bemit('c-button', ['user-panel']) }>
						Connexion
						<Spinner className="c-loader" bemit={ ['white', 'bg-primary'] }
							active={ loading }
						/>
					</button>

				</div>

			</form>

			{/* <div className="c-panel__or">
				ou
			</div> */}

			{/* <button type="button" className={ bemit('c-button', ['user-panel']) }
				onClick={ props.onRegister }>
				Inscription
			</button> */}

			<div className="c-panel__spacer" />
		</>
	);

	async function login (e)
	{
		e.preventDefault();

		setLoading(true);

		setStatus({ });

		const form = new FormData(e.target);

		try
		{
			var { roles, user_id, pages, version } = await request('/auth/login')
			.config({ })
			.post({ email: form.get('email'), password: form.get('password') });
		}
		catch (update)
		{
			return setStatus(update);
		}
		finally
		{
			setLoading(false);
		}

		_ref_version.current = version;

		setRolesStore({ roles, user_id });
		setPagesStore({ pages, version });

		setRedirect(true);
	}
}