import { useEffect, useState } from 'react'

import CrossIcon from '../Icons/Cross'

import PanelRegistrationForm from './PanelComponents/PanelRegistrationForm'
import PanelLoginForm        from './PanelComponents/PanelLoginForm'
import PanelWelcome          from './PanelComponents/PanelWelcome'

import RolesStore from '../../Stores/Roles'

const components =
{
	Registration: PanelRegistrationForm,
	Login: PanelLoginForm,
	Welcome: PanelWelcome
};

export default function UserPanel ({ ...props })
{
	const my_roles = RolesStore.useState(s => s.roles || []);

	const [active_component, setActiveComponent] = useState('Login');

	const Component = components[active_component];

	useEffect(function ()
	{
		if (my_roles.includes('student'))
		{
			setActiveComponent('Welcome');
		}
		else
		{
			setActiveComponent('Login');
		}
	},
	[my_roles]);

	return (
		<div className="c-user-panel">

			<div className="c-user-panel__panel">
				<button type="button" onClick={ props.onClose }
					className="c-user-panel__toggle-btn">
					<CrossIcon className="c-user-panel__toggle-icon-svg" />
					<span className="c-user-panel__toggle-label">
						Fermer
					</span>
				</button>

				<Component onLogin={ e => setActiveComponent('Login') }
					onRegister={ e => setActiveComponent('Registration') }
				/>
			</div>

			<button className="c-user-panel__bg" type="button"
				onClick={ props.onClose }
			/>

		</div>
	);
}