import { useId, useMemo, memo, useEffect, useRef, useState } from 'react'

import { HiOutlineExclamation } from 'react-icons/hi'

import safeNumber from './Toolkit/safeNumber'

import arePropsEqual from '../../Functions/arePropsEqual'
import bemit         from '../../Functions/bemit'

import FormsStore from '../../../Stores/Forms'

const num_allowed =
[
	' ', '.', ',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
];

/**
 * @param { Object } props
 * @param { function } props.onChange
 * @param { function } props.register react-form-hook register
 * @param { String } [props.type]
 * @param { String } [props.label]
 * @param { String } props.name
 * @param { String|Boolean } [props.error]
 *
 * @returns { React.ReactElement }
**/
export default memo(function Input ({ label, onChange, name, ...props })
{
	const ref = useRef();
	const [show_error, setShowError] = useState();

	const type = typeof onChange === 'function'
	? 'legacy'
	: 'pullstate';

	const FORM_NAME = props.form || 'no-form';

	const errors_version_ref = useRef();

	const _submit_id = FormsStore.useState(s => s[FORM_NAME]?._submit_id);

	const state_value = FormsStore.useState(s => s[FORM_NAME]?.[name]);

	const is__readOnly = props['read-only'] ? true : false;

	const default_value = props['default-value'];

	useEffect(function ()
	{
	// réaffiche les erreurs au moment de submit le formulaire,
	// pour gérer le cas où le champ a été modifié (donc l'erreur masquée)
	// mais la validation retourne la même erreur qu'avant modification
		if (_submit_id && _submit_id !== errors_version_ref.current)
		{
			setShowError(true);

			errors_version_ref.current = _submit_id;

			return;
		}

		setShowError(function (show_error)
		{
			return !props.error & show_error === undefined && !_submit_id
			? show_error
			: !props.error && !show_error
			? show_error
			: props.error && show_error
			? show_error
			: props.error ? true : false;
		});
	},
	[props.error, _submit_id]);

	useEffect(() =>
	{
		if (type !== 'pullstate' || !ref || !ref.current) return;

	// pullstate initialisé ou reset, on l'update avec la valeur par défaut
		if (state_value === undefined)
		{
			FormsStore.update(function (state)
			{
				state[props.form] = state[props.form] || { };

				state[props.form][name] = default_value !== undefined
				? default_value
				: '';
			});

		// ignoré si la màj revient à avoir un input vide
			if (default_value || ref.current.value !== '')
			{
				ref.current.value = default_value;
			}

			return;
		}

		if (ref.current.value !== state_value)
		{
			ref.current.value = state_value; // sync de la valeur de l'input
		}
	},
	[type, state_value, default_value, props.form, name]);

	const id = useId();

	const modifiers = useMemo(function ()
	{
		const modifiers = [];

		if (show_error && props.error) modifiers.push('error');
		if (is__readOnly) modifiers.push('disabled');
		if (props.required) modifiers.push('required');

		return modifiers;
	},
	[props.error, is__readOnly, props.required, show_error]);

	const placeholder = useMemo(function ()
	{
		return props.placeholder ? ' ' + props.placeholder : undefined;
	},
	[props.placeholder]);

	const LabelLeftIcon = props['label-left-icon'];

	const shown_value = type === 'legacy'
	? props.value || ''
	: undefined;

	const element_label = label ?
	(
		<label htmlFor={ id } className={
				bemit('c-form-element-label',
				modifiers.concat(['input']).concat(props.bemit || []))
			}>
			{
				LabelLeftIcon
				? <LabelLeftIcon className="c-input__label-icon" />
				: undefined
			}
			{ label }
		</label>
	)
	: undefined;

	return (
		<div className={ bemit('c-input', modifiers.concat(props.bemit || [])) }>

			<div className="c-input__input-wrapper">
				<input type={ props.type } className="c-input__input" id={ id }
					onChange={ type === 'legacy' ? onChange : handleChange }
					name={ name }
					value={ shown_value }
					disabled={ props['read-only'] === true }
					placeholder={ placeholder }
					autoComplete={ props.autoComplete || 'off' }
					onKeyDown={ props.onKeyDown }
					ref={ ref }
				/>

				<span className="c-input__border" />
			</div>

			{
				show_error && props.error ?
				(
					<span className={ bemit('c-field-error', ['input']) }>
						<HiOutlineExclamation className="c-field-error__icon" />
						{ props.error }
					</span>
				)
				: undefined
			}

			{ element_label }
		</div>
	);

	function handleChange (e)
	{
		let value = e.target?.value || '';

		if (props['safe-number'])
		{
			value = safeNumber(value, { 'allow-negative': props['allow-negative'] });
		}

		FormsStore.update(function (state)
		{
			state[props.form] = state[props.form] || { };

			state[props.form][name] = value;
		});

		if (show_error) setShowError(false);
	}
},
arePropsEqual);